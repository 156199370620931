export default {
    name: "EducationIndex",
    created () {
        this.$nextTick(function () {
            $.each(this.Earr, (i, c) => {
                if (i >= 4) {
                    this.GetEducation(c.meta.id, i);
                }
            });
        });
    },
    mounted () {
        this.$nextTick(function () { });
    },
    data () {
        return {
            Earr: this.$router.options.routes[4].children
        };
    },
    computed: {},
    watch: {},
    methods: {

        // 获取数据
        GetEducation (id, i) {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$$.mibb + "GetEducationContent",
                data: { id: id, lang: localStorage.getItem("langIH") || 1 },
                success: data => {
                    if (data.status == "SUCCESS") {
                        this.Earr[i].meta.description = data.text[0].description;
                        this.$forceUpdate();
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        }
    }
};
